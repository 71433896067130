<template>
  <div class="information-container relative flex flex-col items-center justify-center">
    <div class="absolute z-10 top-6 left-1/2 transform -translate-x-1/2 cursor-pointer" @click="goToEnigma()">
      <img src="../assets/icons/close.svg" alt="">
    </div>
    <SocietyText class="mt-40 md:mt-0" :delay="1.5" />
  </div>
</template>

<script>
import SocietyText from '@/components/SocietyText.vue'
import { mapGetters } from "vuex";
export default {
  name: 'Information',
  components: {  
    SocietyText
  },
  computed: {
    ...mapGetters({
      lastEnigmaId: "user/lastEnigmaId"
    })
  },
  methods: {
    goToEnigma() {
      this.$router
        .push({ name: 'hub', params: { swiperIndex: this.lastEnigmaId || '1' } })
        .catch(console.log)
    }
  }
}
</script>

<style lang="scss" scoped>
  .information-container {
    background: url('../assets/bg/intro-min.jpg');
    background-size: cover;
    height: 100vh;
    @media screen and (min-width: 768px) {
      background: url('../assets/bg/intro.jpg');
      background-size: cover;
    }
  }
</style>